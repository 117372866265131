//TODO: Move all constants into seperate folder

import type { VolumetricType } from '@/views/create-shipment/enums';
import type Role from '@/enums/role';

enum ProvideInjectVariableName {
    IS_SHIPMENT_STEP_MODAL_OPEN = 'IS_SHIPMENT_STEP_MODAL_OPEN'
}

const LOCALSTORAGE_PROPERTIES = {
    PICKUP_DEFAULT_STATES: 'pickupDefaultStates',
    LANGUAGE: 'language',
    MSAL_INTERACTION_STATUS: 'msal.interaction.status',
    MSAL_CONFIG: 'msalConfig'
} as const;

type Shipper = {
    name: string;
    contactId: string;
};

type PhoneNumber = {
    prefix: string;
    main: string;
};

type UserType = 'partner' | 'employee';

type ParcelShopAddressPOST = {
    Distance: number;
    StreetNumber: string | null;
    Street: string | null;
    CountryCode: string | null | undefined;
    ZIPCode: string | null;
};

type ParcelshopAddressResponse = {
    ParcelShopName: string;
    StreetNumber: string;
    Street: string;
    City: string;
    CountryCode: string;
    ZIPCode: string;
};

type ParcelShopCoordinatesResponse = {
    Latitude: number;
    Longitude: number;
};

type ParcelShopOpeningHoursSlotResponse = {
    From: string;
    To: string;
};

type ParcelShopOpeningDaysResponse = {
    DayOfWeek: string;
    OpeningHours: ParcelShopOpeningHoursSlotResponse[];
};

type ParcelShopResponse = {
    Address: ParcelshopAddressResponse;
    StraightlineDistance: string;
    Coordinates: ParcelShopCoordinatesResponse;
    OpeningTimes: ParcelShopOpeningDaysResponse[];
    ParcelShopID: string;
};

type ParcelShopListResponse = {
    ParcelShop: ParcelShopResponse[];
};

type AttributeStoreResponse = {
    username: string;
    role: Role[];
    shipper: Shipper[];
    incoterm: number[];
    locations: string[];
    volumetricType: VolumetricType;
};

type EnvironmentConfig = {
    VITE_API: string;
    VITE_AZURE_B2C_AUTHORITY: string;
    VITE_AZURE_B2C_CLIENT_ID: string;
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: string;
};

type ErrorData = {
    detail: string;
    error?: string;
    message?: string;
    status: number;
    title: string;
    type?: string;
    args?: string;
    violations: string[];
    invalidCharacters: InvalidCharacterObject[];
};

type InvalidCharacterObject = {
    character: string;
    position: number;
};

export {
    LOCALSTORAGE_PROPERTIES,
    type UserType,
    type Shipper,
    type ParcelshopAddressResponse,
    type ParcelShopListResponse,
    type ParcelShopResponse,
    type ParcelShopAddressPOST,
    type ParcelShopOpeningDaysResponse,
    type AttributeStoreResponse,
    type PhoneNumber,
    type EnvironmentConfig,
    type ErrorData,
    ProvideInjectVariableName
};
