import { createI18n } from 'vue-i18n';
import de from './global-translations/de.json';
import en from './global-translations/en.json';
import { LOCALSTORAGE_PROPERTIES } from '@/types';

/**
 * Returns the currently in the localStorage saved language. If there is no
 * language saved, it returns 'en' as default language.
 * @returns {string} language
 */
const getLanguageFromLocalStorage = () => {
    let language = localStorage.getItem(LOCALSTORAGE_PROPERTIES.LANGUAGE);
    if (!language) {
        language = 'en';
    }

    return language;
};

/**
 * Saves the passed in language string in the localStorage
 * @param {string} language
 */
const setLanguageInLocalStorage = (language: string) => {
    localStorage.setItem(LOCALSTORAGE_PROPERTIES.LANGUAGE, language);
};

/**
 * Creates a i18n instance will the necessary configuration.
 * legacy has to be false, because otherwise it supports only the option API syntax.
 * The default language is saved in the localstorage and therefore evaluated
 * every time when the app is started. If there is none in localstorage (first start),
 * en is set as default language.
 * The fallbackLocale is a additional backup if no default language can be found.
 * The messages properties references those translations, which can be used globally
 * and therefore being referenced in multiple components. They can be used with the
 * "$t" function, contrary to the local translation, which has to be used with
 * the "t" function.
 */
const createI18nInstance = () => {
    const i18nInstance = createI18n({
        legacy: false, // for composition api
        locale: getLanguageFromLocalStorage() || 'en',
        fallbackLocale: 'en',
        messages: { de, en }
    });

    return i18nInstance;
};

export { getLanguageFromLocalStorage, setLanguageInLocalStorage, createI18nInstance };
