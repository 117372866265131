import type { EnvironmentConfig } from '@/types';

const devEnvironment: EnvironmentConfig = {
    VITE_API: 'https://api-dev.gls-group.net',
    VITE_AZURE_B2C_AUTHORITY: 'https://glsgrouptest.b2clogin.com/glsgrouptest.onmicrosoft.com/B2C_1A_LOCAL_SUSI',
    VITE_AZURE_B2C_CLIENT_ID: 'a0462fe1-7da4-4d67-883f-290bcbde8d39',
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: 'glsgrouptest.b2clogin.com'
};

const stageEnvironment: EnvironmentConfig = {
    VITE_API: 'https://api-qas.gls-group.net',
    VITE_AZURE_B2C_AUTHORITY: 'https://glsgrouptest.b2clogin.com/glsgrouptest.onmicrosoft.com/B2C_1A_LOCAL_SUSI',
    VITE_AZURE_B2C_CLIENT_ID: 'a0462fe1-7da4-4d67-883f-290bcbde8d39',
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: 'glsgrouptest.b2clogin.com'
};
const prodEnvironment: EnvironmentConfig = {
    VITE_API: 'https://api.gls-group.net',
    VITE_AZURE_B2C_AUTHORITY: 'https://glsgroup.b2clogin.com/glsgroup.onmicrosoft.com/B2C_1A_LOCAL_SUSI',
    VITE_AZURE_B2C_CLIENT_ID: '78df48dd-e52a-485f-8509-4ce68403660c',
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: 'glsgroup.b2clogin.com'
};

export { devEnvironment, stageEnvironment, prodEnvironment };
