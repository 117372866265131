import '@/assets/styles/index.scss';
import { createPinia, type Pinia } from 'pinia';
import { createI18nInstance } from '@/services/translation/i18n';
import elementPlus from '@/plugins/element-plus';
import { VueQueryPlugin as vueQuery } from '@tanstack/vue-query';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { devEnvironment, prodEnvironment, stageEnvironment } from '@/constants/env';
import EnvironmentHostName from '@/enums/environment-hostname';
import { type EnvironmentConfig } from '@/types';
import { createApp } from 'vue';

const configureDynatrace = (): void => {
    if (window.env.VITE_APP_TITLE.includes('STAGE') || window.env.VITE_APP_TITLE.includes('PROD')) {
        const scriptElement: HTMLScriptElement = document.createElement('script');
        scriptElement.src = 'https://js-cdn.dynatrace.com/jstag/164ae1b51de/bf90494dmi/43b80643b8cf79f3_complete.js';
        scriptElement.type = 'text/javascript';
        scriptElement.setAttribute('crossOrigin', 'anonymous');

        document.head.appendChild(scriptElement);
    }
};

const configureEnv = (): void => {
    const redirectURI: string = `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ':' + window.location.port : ''
    }`;

    const envData = {
        VITE_APP_TITLE: `GLS Partner Portal (${getEnvName()})`,
        VITE_AZURE_AD_AUTHORITY: 'https://login.microsoftonline.com/e6170c30-202d-4926-b525-b8b882873f3b',
        VITE_AZURE_AD_CLIENT_ID: 'f73b84b3-21b3-4d73-942c-e5f7591548e3',
        VITE_MSAL_REDIRECT_URI: redirectURI,
        VITE_MSAL_POSTLOGOUT_REDIRECT_URI: redirectURI
    };

    window.env = { ...envData, ...getEnvConfig() };
};

const getEnvConfig = (): EnvironmentConfig => {
    // TODO use dev config for localhost when AS is fixed
    switch (getHostname()) {
        case EnvironmentHostName.LOCAL:
        case EnvironmentHostName.STAGE:
            return stageEnvironment;
        case EnvironmentHostName.PROD:
            return prodEnvironment;
        default:
            return devEnvironment;
    }
};

const getEnvName = (): string => {
    switch (getHostname()) {
        case EnvironmentHostName.LOCAL:
            return 'LOCAL';
        case EnvironmentHostName.STAGE:
            return 'STAGE';
        case EnvironmentHostName.PROD:
            return 'PROD';
        default:
            return 'DEV';
    }
};

const getHostname = (): string => {
    return window.location.hostname.replace('www.', '');
};
/**
 * The app is wrapped into an async due to the necessity of fetching
 * the environment variable 'env' before other env-dependent imports happen.
 * Only the fetch function needs an async call, but as the dependent imports need
 * to execute afterwards, those have to be in await syntax as well.
 * The top level async is wrapped into an IFFE, otherwise the app wouldn't execute
 * on startup.
 */
(async (): Promise<void> => {
    configureEnv();
    document.title = window.env.VITE_APP_TITLE;
    configureDynatrace();

    const { default: App } = await import('@/App.vue');
    const { default: router } = await import('@/routing/router');

    const pinia: Pinia = createPinia();
    pinia.use(piniaPluginPersistedstate);

    const app = createApp(App);
    app.use(pinia).use(createI18nInstance()).use(router).use(elementPlus).use(vueQuery).mount('#app');
})();
